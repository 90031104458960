import React, { useState, useEffect } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import FlexComponent from "../FlexComponent";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { upsertPipeline } from "../../utils/urls";
import axios from '../../utils/axios';
import { InputText } from "primereact/inputtext";

export function Stages({ toast, stages, setStages, stage, pipelinesList, pipeline }) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({ id: stage.id });

    const [stageEdit, setStageEdit] = useState(false);
    const [stageName, setStageName] = useState(stage.name);
    const [pipelineId, setPipelineId] = useState('');
    const [stageId, setStageId] = useState('');
    const [stagesByPipeline, setStagesByPipeline] = useState([]);
    const [relatedStages, setRelatedStages] = useState(stage.related_stages)
    const [dueDate, setDueDate] = useState(stage.stage_due)

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        width: "100%",
        background: "white",
    };


    useEffect(() => {
        if (pipelineId) {
            const selectedPipeline = pipelinesList.find(item => item.id === pipelineId);
            setStagesByPipeline(selectedPipeline ? selectedPipeline.stages : []);
        }
    }, [pipelineId, pipelinesList]);


    const deleteStage = (id) => {
        const updatedData = stages.filter(item => item.id !== id)
        setStages(updatedData)
    }

    const removeRelatedStage = (relatedStageId) => {
        setStages(prevStages =>
            prevStages.map(stage =>
                stage.id === stageId
                    ? {
                        ...stage,
                        related_stages: stage.related_stages.filter(id => id !== relatedStageId)
                    }
                    : stage
            )
        );

        setRelatedStages(prev =>
            prev.filter(stage => stage.id !== relatedStageId)
        );
    };

    const updateStageName = () => {
        setStages(prevStages =>
            prevStages.map(list =>
                list.id === stage.id
                    ? {
                        ...list,
                        stage_due : {
                            ...dueDate
                        },
                        name: stageName
                    }
                    : list
            )
        );
    }

    const addRelatedStages = () => {
        const findStage = stagesByPipeline.find(item => item.id === stageId);
        // console.log(findStage)
        setStages(prevStages =>
            prevStages.map(list =>
                list.id === stage.id
                    ? {
                        ...list,
                        related_stages: list.related_stages.some(stage => stage.id === findStage.id)
                            ? list.related_stages
                            : [...list.related_stages, findStage]
                    }
                    : list
            )
        );
        setRelatedStages(prev =>
            prev.some(stage => stage.id === findStage.id)
                ? prev
                : [...prev, findStage]
        );
    }

    console.log(dueDate)

    return (
        <div className="list-row stagesSection" ref={setNodeRef} style={style}>
            {!pipeline?.is_ghl_pipeline ? (
                <FlexComponent gap="1rem" style={{  padding: "10px",  width: "100%" }}>
                    <FlexComponent gap=".5rem">
                        <span style={{ cursor: "pointer" }} {...attributes} {...listeners}>⣿</span>
                        <p>{stage.name}</p>
                        <div >
                            <i style={{ cursor: "pointer" }} onClick={() => setStageEdit(true)} className="pi pi-pencil"></i>
                            <i style={{ cursor: "pointer", paddingLeft: ".5rem" }} onClick={() => {
                                deleteStage(stage.id)
                            }} className="pi pi-trash"></i>
                        </div>
                    </FlexComponent>

                    <Dialog header="Edit Stage" footer={<div><Button onClick={() => {
                        setStageEdit(false)
                        updateStageName()
                    }} >Close</Button></div>} visible={stageEdit} style={{ width: '50vw', minHeight: '65vh' }}
                        onHide={() => {
                            setStageEdit(false)
                            updateStageName()
                        }}>
                        <div>
                            <div className='inputContainerDiv' style={{ width: '100%' }}>
                                <div htmlFor="stageName" style={{padding: ".5rem 0" , fontWeight: 'bold' }}>Stage Name</div>
                                <InputText size="small" style={{ width: '100%' }} id="stageName" value={stageName}
                                    onChange={(e) => setStageName(e.target.value)}
                                />
                            </div>
                            <div style={{ marginTop: "1rem" }} >
                                    <div style={{ fontWeight: 'bold' }}>Due Date</div>
                                <FlexComponent style={{ padding: ".5rem 0" }} >
                                    <div style={{ width: "30%" }} >
                                        <div>Days</div>
                                        <InputText name="days" value={dueDate?.days || stage.due_in_days || 0} onChange={(e)=>{
                                            setDueDate((prev)=> ({
                                                ...prev,
                                                days : parseInt(e.target.value)
                                            }))
                                        }} min={0} style={{ width: "100%" }} type="number" />
                                    </div>
                                    <div style={{ width: "30%" }}>
                                        <div>Hours</div>
                                        <InputText name="hours" value={dueDate?.hours || stage.due_in_hours || 0} onChange={(e)=>{
                                            if(e.target.value > 24){
                                                setDueDate((prev)=> ({
                                                    ...prev,
                                                    hours : 24
                                                }))
                                            }else{
                                                setDueDate((prev)=> ({
                                                    ...prev,
                                                    hours : parseInt(e.target.value)
                                                }))
                                            }
                                        }} min={0} max={24} style={{ width: "100%" }} type="number" />
                                    </div>
                                    <div style={{ width: "30%" }}>
                                        <div>Minutes</div>
                                        <InputText name="minutes" value={dueDate?.minutes || stage.due_in_minutes || 0} onChange={(e)=>{
                                            if(e.target.value > 60){
                                                setDueDate((prev)=> ({
                                                    ...prev,
                                                    minutes : 60
                                                }))
                                            }else{
                                                setDueDate((prev)=> ({
                                                    ...prev,
                                                    minutes : parseInt(e.target.value)
                                                }))
                                            }
                                        }} min={0} style={{ width: "100%" }} type="number" />
                                    </div>
                                </FlexComponent>
                                <p style={{padding: ".5rem 0" , fontWeight: 'bold' }}>Add Related Stages</p>
                                {relatedStages?.map((item) => (
                                    <FlexComponent key={item.id} style={{ border: "1px solid grey", borderRadius: "5px", padding: ".5rem", margin: "1rem 0" }} >
                                        <p>{item.name}</p>
                                        <i className="pi pi-trash" style={{ cursor: "pointer" }} onClick={() => {
                                            removeRelatedStage(item.id)
                                        }} ></i>
                                    </FlexComponent>
                                ))}
                                <FlexComponent className='inputContainer' gap='.3rem' alignItems='center' justifyContent='start'>
                                    <div className='inputContainerDiv' style={{ width: '40%', }}>
                                        <Dropdown
                                            id="pipelineId"
                                            value={pipelineId}
                                            options={pipelinesList.map(pipeline => ({
                                                label: pipeline.name,
                                                value: pipeline.id,
                                            })) || []}
                                            style={{ width: '100%' }}
                                            onChange={(e) => setPipelineId(e.value)}
                                            placeholder="Select Pipeline"
                                        />
                                    </div>

                                    <div className='inputContainerDiv' style={{ width: '45%', }}>
                                        <Dropdown
                                            id="stageId"
                                            value={stageId}
                                            options={stagesByPipeline.map(stage => ({
                                                label: stage.name,
                                                value: stage.id,
                                            })) || []}
                                            style={{ width: '100%' }}
                                            onChange={(e) => setStageId(e.value)}
                                            placeholder="Select Stage"
                                        />
                                    </div>

                                    <div style={{ width: "5%" }} >
                                        <i onClick={() => {
                                            addRelatedStages()
                                        }} style={{ cursor: "pointer", background: "green", padding: ".7rem",  borderRadius: "5px" }} className="pi pi-plus" ></i>
                                    </div>
                                </FlexComponent>
                            </div>
                        </div>
                    </Dialog>
                </FlexComponent>
            ) : (
                <div style={{ cursor: "auto", border: "1px solid grey", padding: "10px", borderRadius: "10px", width: "100%" }}>
                    <p>{stage.name}</p>
                </div>
            )}
        </div>
    );
}
