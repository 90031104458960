import React, { useEffect, useRef, useState } from 'react'
import GetStarted from '../../components/form/GetStarted'
import FlexComponent from '../../components/FlexComponent'
import './form.css'
import { Formik } from 'formik'
import * as yup from "yup";
import { Button } from 'primereact/button'
import WorkHistory from '../../components/form/WorkHistory'
import HowMuchMoneySaved from '../../components/form/HowMuchMoneySaved'
import YourCredit from '../../components/form/YourCredit'
import AddressHistory from '../../components/form/AddressHistory'
import ContactInformation from '../../components/form/ContactInformation'
import WhatTypeOfHome from '../../components/form/WhatTypeOfHome'
import CoSignerInfo from '../../components/form/CoSignerInfo'
import InternalDetails from '../../components/form/InternalDetails'
import axios from '../../utils/axios'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { verifyToken, form_submit, getAvailableUsers } from '../../utils/urls'
import jwt_decode from "jwt-decode";
import { setUser } from '../../utils/userSlice'
import { RefreshToken } from '../../utils/refreshToken'
import Swal from 'sweetalert2'
import { Toast } from 'primereact/toast'
import { Nav } from '../../shared/Nav'
import { get_form_initials } from '../../utils/urls';
import logo from '../../Images/homes_sale_logo.png'
import { Avatar } from 'primereact/avatar'


const formSchema = yup.object().shape({
    firstName: yup.string().trim().required("required"),
    lastName: yup.string().trim().required("required"),
    language: yup.string().required("required"),
    work_history: yup.array().min(1, 'work history is Required').required('This field is required'),
    address_history: yup.array().min(1, 'Address is Required').required('This field is required'),
    // title: yup.string().required("required"),
    // yearly_income: yup.string().required("required"),
    // time_at_position: yup.string().required("required"),
    total_amount_saved: yup.number().required("required"),
    estimated_credit_score: yup.number().required("required"),
    cell_phone_number: yup.string()
        .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Cell phone number must be in the format (xxx) xxx-xxx')
        .required('Cell phone number is required'),
    email: yup.string().email("invalid email").required("required"),
    // do_you_have_a_cosigner: yup.string().required("required"),
    lead_source: yup.string().required("required"),
    which_homes_for_sale_region: yup.string().required("required"),
    application_pipeline: yup.string().required("required"),
    application_by: yup.string().required("required"),
});



function Form() {

    const [locationData, setLocationData] = useState();
    const [ghlUserId, setGhlUserId] = useState(null);
    const toast = useRef(null);
    const navigate = useNavigate()
    const location = useLocation();
    const isSuperuser = useSelector((state) => state.user.isSuperuser);
    const image = useSelector((state) => state.user.image);
    const userLable = useSelector((state) => state.user.userLable);
    const userName = useSelector((state) => state.user.userName);
    const userId = useSelector((state) => state.user.userId);
    const is_leader = useSelector((state) => state.user.is_leader);
    const [scrolling, setScrolling] = useState(false);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [languageSelect, setLanguageSelect] = useState("")
    const [availableUsers, setAvailableUsers] = useState([])

    useEffect(() => {
        const authTokens = JSON.parse(localStorage.getItem('authTokens'))
        const refresh = authTokens?.refresh
        if (refresh) {
            const body = JSON.stringify({
                token: refresh,
            });
            axios
                .post(verifyToken, body, {
                    headers: { "Content-Type": "application/json" },
                })
                .then((response) => {
                    if (response.status === 200) {
                        const access = authTokens?.access
                        const decodedToken = jwt_decode(access)

                        dispatch(setUser({ userId: decodedToken.user_id, isSuperuser: decodedToken.is_superuser, userLable: decodedToken.userLable, image: decodedToken.image, userName: decodedToken.userName, ghlUserId: decodedToken.ghlUserId, is_leader: decodedToken.is_leader }));
                        localStorage.setItem("prevUrl", window.location.pathname);
                        // const superUser = decodedToken.is_superuser
                        // if (superUser) {
                        //     dispatch(setUser({ userId: decodedToken.user_id, isSuperuser: decodedToken.is_superuser }));
                        //     localStorage.setItem("prevUrl", window.location.pathname);
                        // }
                        // else {
                        //     navigate('/')
                        // }
                    }
                })
                .catch((err) => {
                    localStorage.removeItem('authTokens');
                    localStorage.removeItem('prevUrl');
                    dispatch(setUser({ userId: null, isSuperuser: null, userLable: null, image: null, userName: null, ghlUserId: null, is_leader: null }));
                    // dispatch(change({ id: null ,username: null, image: null }));
                    // setIsLoggedIn(false)
                    navigate('/login')
                });
        } else {
            localStorage.removeItem('authTokens');
            localStorage.removeItem('prevUrl');
            dispatch(setUser({ userId: null, isSuperuser: null, userLable: null, image: null, userName: null, ghlUserId: null, is_leader: null }));
            // dispatch(change({ id: null ,username: null, image: null }));
            // setIsLoggedIn(false)
            navigate('/login')
        }
    }, [isSuperuser])

    useEffect(() => {
        const intervalId = setInterval(async () => {
            await RefreshToken();
        }, 3600000);

        return () => clearInterval(intervalId);
    }, []);

    const handleLogout = () => {
        Swal.fire({
            title: 'Are you sure to log out?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.isConfirmed) {
                localStorage.removeItem('authTokens');
                localStorage.removeItem('prevUrl');
                // dispatch(change({ username: null, image: null }));
                navigate('/login')
            }
        })
    }
    function changeIncomeColor(values) {
        try {
            // const income = parseInt(applicantIncome?.replace(/\D/g, ''));
            // console.log(income)
            // if (isNaN(income)) return '';
            const applicantIncome = values.work_history?.find((work) => work.status)?.yearly_income
            if (applicantIncome) {
                if (applicantIncome >= 80000) {
                    return '#2FC623';
                } else if (applicantIncome < 80000 && applicantIncome >= 65000) {
                    return 'orange';
                } else {
                    return '#EB4243'
                }

            }

        } catch (error) {
            return ''
        }
    }

    function changeCreditColor(applicantCredit) {
        try {
            const credit = parseInt(applicantCredit);
            // console.log(credit)
            if (isNaN(credit)) return '';
            if (credit >= 620) {
                return '#2FC623';
            } else if (credit < 620 && credit >= 500) {
                return '#F7DF01';
            } else {
                return 'red'
            }

        } catch (error) {
            return ''
        }
    }

    function changeAvailableColor(cashAvailable) {
        try {
            // console.log(cashAvailable)
            // const amount = parseInt(cashAvailable?.replace(/\D/g, ''));
            if (isNaN(cashAvailable)) return '';
            if (cashAvailable >= 45000) {
                return '#2FC623';
            } else if (cashAvailable < 45000 && cashAvailable >= 20000) {
                return '#FF9A00';
            } else if (cashAvailable) {
                return 'red'
            }

        } catch (error) {
            return ''
        }
    }

    const initialValues = {
        // I can definitely help. Let's get started!
        firstName: "",
        middleName: "",
        lastName: "",
        language: "",
        otherLanguage: "",
        where_are_you_in_the_process: "",
        will_this_be_your_first_time: "",

        // What do you do for work?
        work_history: [],
        do_you_have_any_additional_source_of_income: "",

        // How much money do you have saved for buying a house so far?
        total_amount_saved: "",
        is_any_of_it_cash_on_hand: "",
        any_additional_savings: "",
        notes_about_savings: "",

        // How is your credit?
        estimated_credit_score: "",
        date_of_birth: "",
        social_security_no: "",
        status_in_country: "",

        // Where do you live now?
        address_history: [],
        do_you_own_any_other_property: "N",
        other_property_owned: [],

        // Lets confirm your contact information
        cell_phone_number: "",
        email: "",
        best_days_and_times_to_reach: "",

        // What type of home are you looking for?
        home_type: [],
        bedrooms: "",
        bathrooms: "",
        do_you_have_price_range_in_mind: "",
        why_are_you_looking_to_buy_this_time: "",
        purchase_type: "",
        notes_about_home_search: "",
        preapproved_already: "",
        preapproval_details: "",
        specific_area_interested_in: [],
        // any_other_areas: [],
        do_you_have_a_cosigner: "",
        // how_soon_are_you_available_to_go_see_houses: "",

        // Co-Signer Info
        cosigner_list: [],
        // cosigner_list: {
        //     co_borrower_first_name: "",
        //     co_borrower_middle_name: "",
        //     co_borrower_last_name: "",
        //     cb_DOB: "",
        //     cb_credit_score: "",
        //     cb_title: "",
        //     cb_yearly_income: "",
        //     cb_time_at_position: "",
        //     cb_company_name: "",
        //     cb_employment_type: "",
        //     cb_status: "",
        //     cb_start_date: "",
        //     cb_end_date: "",
        //     cb_work_history_description: "",
        //     cosigner_street_address: "",
        //     cosginer_city: "",
        //     cosigner_state: "",
        //     cosigner_zip_code: "",
        //     cosigner_cell_phone_number: "",
        //     cosigner_email: "",
        //     cosigner_security_no: "",
        // },
        cb_other_time_at_position: "",

        // Internal Details
        lead_source: "Homes For Sale Lead",
        other_lead_source: "",
        does_buyer_name_match_original_lead: "",
        name_of_lead: "",
        cell_number_of_lead: "",
        application_pipeline: "",
        application_by: "",
        which_homes_for_sale_region: "",

        address: ""
    };

    const formatDate = (date) => {
        if (!date) return ''
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleClick = (event, id) => {
        event.preventDefault();
        let element = document.getElementById(id)
            ;
        if (!element && id === 'cosignerId') {
            element = document.getElementById('cosignerQuestionId')
        }
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
            const elementRect = element.getBoundingClientRect();
            const absoluteElementTop = elementRect.top + window.pageYOffset;
            window.scrollTo({ top: absoluteElementTop - 120, behavior: 'smooth' });
        }
    };

    const handleFormSubmit = async (values, onSubmitProps) => {
        try {
            setLoading(true);
            console.log('Submit requested')
            // await submitForm(values, onSubmitProps);
            const { otherLanguage, other_lead_source, cb_other_time_at_position, ...data } = values
            data.form_name = "New App"
            data.language = data.language === "Other" ? otherLanguage : data.language
            data.lead_source = data.lead_source === "Other" ? other_lead_source : data.lead_source
            data.date_of_birth = formatDate(data.date_of_birth)
            // if (data.do_you_have_a_cosigner === "Yes") {
            //     data.cosigner_list.cb_time_at_position = data.cosigner_list.cb_time_at_position === 'Other' ? cb_other_time_at_position : data.cosigner_list.cb_time_at_position
            //     data.cosigner_list.cb_start_date = formatDate(data.cosigner_list.cb_start_date)
            //     data.cosigner_list.cb_end_date = formatDate(data.cosigner_list.cb_end_date)
            //     data.cosigner_list.cb_DOB = formatDate(data.cosigner_list.cb_DOB)
            //     data.cosigner_list.cosigner_cell_phone_number = parseInt(data.cosigner_list.cosigner_cell_phone_number?.replace(/\D/g, '')) || "";
            // } else {
            //     data.cosigner_list = {}
            // }

            data.cosigner_list = data.cosigner_list.map((cosigner) => {
                cosigner.cb_time_at_position = cosigner.cb_time_at_position === 'Other' ? cb_other_time_at_position : cosigner.cb_time_at_position;
                cosigner.cb_start_date = formatDate(cosigner.cb_start_date);
                cosigner.cb_end_date = formatDate(cosigner.cb_end_date);
                cosigner.cb_DOB = formatDate(cosigner.cb_DOB);
                cosigner.cosigner_cell_phone_number = parseInt(cosigner.cosigner_cell_phone_number?.replace(/\D/g, '')) || "";
                return cosigner;
            });
            if (data.cosigner_list.length > 0) {
                data.do_you_have_a_cosigner = "Yes"
            } else {
                data.do_you_have_a_cosigner = "No"
            }

            data.cell_phone_number = parseInt(data.cell_phone_number?.replace(/\D/g, '')) || "";
            data.cell_number_of_lead = parseInt(data.cell_number_of_lead?.replace(/\D/g, '')) || "";
            data.work_history = data.work_history.map(({ otherTimeAtPostion, start_date, end_date, ...entry }) => ({
                ...entry,
                time_at_position: entry.time_at_position === 'Other' ? otherTimeAtPostion : entry.time_at_position,
                start_date: formatDate(start_date),
                end_date: formatDate(end_date)
            }))
            // setTimeout(() => {
            //     setLoading(false);
            // }, 1000);
            console.log(data)
            const response = await axios.post(form_submit, data, {
                headers: { 'Content-Type': 'application/json' },
            });
            if (response.status === 200) {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Form Submitted Successfully",
                    showConfirmButton: false,
                    timer: 1500,
                });
                setLoading(false)
                onSubmitProps.resetForm();
            } else {
                Swal.fire({
                    position: "center",
                    icon: "warning",
                    title: "Some Error Happened",
                    showConfirmButton: false,
                    timer: 1500,
                });
                setLoading(false)
            }
        } catch (error) {
            Swal.fire({
                position: "center",
                icon: "warning",
                title: "Some Error Happened",
                showConfirmButton: false,
                timer: 1500,
            });
            setLoading(false)
        }
    };

    // useEffect(() => {
    //     const getLocationList = async () => {
    //         const list = await axios.get('https://backendapi.homesforsalemedia.com/api/get_form_initials/', {
    //             headers: { 'Content-Type': 'application/json' },
    //         })
    //         // console.log(list.data)
    //         const data = list.data
    //         const mergedData = [
    //             data.default,
    //             ...data.location_user_data
    //         ];
    //         console.log(mergedData)
    //         setLocationData(mergedData)
    //     }
    //     getLocationList()
    // }, [])

    const getLocationList = async () => {
        if (userId) {
            try {
                const response = await axios.get(`${get_form_initials}?userId=${userId}`, {
                    headers: { 'Content-Type': 'application/json' },
                });
                if (response.status === 200) {
                    const data = response.data;
                    const mergedData = [
                        // data.default,
                        ...data.location_user_data
                    ];
                    console.log(mergedData);
                    setLocationData(mergedData);
                    setGhlUserId(data.ghl_user_id)
                }
            } catch (error) {
                // Handle errors here
                console.error('Error fetching location list:', error);
            }
        }
    };

    const getAvailableUsersList = async () => {
        if (languageSelect) {
            try {
                const response = await axios.get(`${getAvailableUsers}?type=Live Assignment&language=${languageSelect}`, {
                    headers: { 'Content-Type': 'application/json' },
                });
                if (response.status === 200) {
                    const data = response.data;
                    console.log(data)
                    setAvailableUsers(data)
                }
            } catch (error) {
                // Handle errors here
                console.error('Error fetching location list:', error);
            }
        }
    };

    useEffect(() => {
        getLocationList();
    }, [get_form_initials, userId]);

    useEffect(() => {
        getAvailableUsersList()
    }, [languageSelect])

    function getCurrentYearlyIncome(values) {
        try {
            const findIncome = values.work_history?.find((work) => work.status)
            if (findIncome) {
                return findIncome.yearly_income
            }

        } catch (error) {
            return ''
        }
    }

    function getInitials(string) {
        let words = string?.split(' ');
        let initials = words?.map(word => word.charAt(0).toUpperCase());
        return initials?.join('');
    }

    function formatNumber(number) {
        // Convert the input to a number
        if (number) {
            const num = parseInt(number, 10);

            if (isNaN(num)) {
                throw new Error('Invalid number');
            }

            if (num >= 1000 && num < 1000000) {
                return (num / 1000).toFixed(1) + 'k';
            } else if (num >= 1000000 && num < 1000000000) {
                return (num / 1000000).toFixed(1) + 'M';
            } else if (num >= 1000000000) {
                return (num / 1000000000).toFixed(1) + 'B';
            } else {
                return num.toString();
            }
        }
    }

    return (
        <div>
            <Toast ref={toast} />
            <header style={{background: "black"}} className={scrolling ? 'sticky' : ''}>
                <Nav handleLogout={handleLogout} isSuperuser={isSuperuser} userLable={userLable} image={image} userName={userName} is_leader={is_leader} />
            </header>
            <div style={{ padding: "2.5rem 0", background: "white", position: 'relative' }}>

                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={initialValues}
                    validationSchema={formSchema}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        setFieldValue,
                        handleSubmit,
                        isSubmitting
                        // setFieldValue,
                        // resetForm,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <FlexComponent className="formDiv" justifyContent='end' alignItems='start' gap="1rem">
                                {/* <AutoComplete/> */}
                                {/* <img className="navigationClass" src={logo} style={{ width: '150px', height: '150px' }} alt="" /> */}
                                <FlexComponent className="inputContainer" flexDirection='column' style={{ zIndex: '1', background: '#F2F2F2', width: '70%', boxShadow: '0px 4px 10px 4px rgb(0 0 0 / 20%)', borderRadius: '15px' }}>
                                    <GetStarted formName="New App" setLanguageSelect={setLanguageSelect} values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange} />
                                    <WorkHistory values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange} />
                                    <HowMuchMoneySaved values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange} />
                                    <YourCredit values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange} />
                                    <AddressHistory values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange} />
                                    {/* <SampleAddress values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange} /> */}
                                    <ContactInformation values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange} />
                                    <WhatTypeOfHome values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange} setFieldValue={setFieldValue} />
                                    <CoSignerInfo values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange} />
                                    <InternalDetails ghlUserId={ghlUserId} locationData={locationData} values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange} />

                                    {/* {Object.keys(errors).length > 0 && <span style={{color: 'red'}}>please fill the required fields</span>} */}
                                    <FlexComponent justifyContent="center" style={{ padding: "1rem" }}>
                                        <Button type="submit" style={{background: "black"}} label="Submit" icon="pi pi-check" loading={loading} disabled={isSubmitting} />
                                    </FlexComponent>
                                </FlexComponent>
                                <FlexComponent className="navigationClass" flexDirection="column" style={{ width: "20%", marginTop: ".2rem" }}>
                                    <FlexComponent justifyContent='start' alignItems="end" gap='.8rem' >
                                        <div>
                                            <p style={{ fontFamily: "DINCondensedC", textAlign: "center", color: changeIncomeColor(values), fontSize: '24px' }}>{formatNumber(getCurrentYearlyIncome(values)) || 0}</p>
                                            <p>Income</p>
                                        </div>
                                        <div>
                                            <p style={{ fontFamily: "DINCondensedC", textAlign: "center", color: changeCreditColor(values?.estimated_credit_score || ''), fontSize: values?.estimated_credit_score.length > 5 ? '12px' : '24px' }}>{values?.estimated_credit_score || 0}</p>
                                            <p>Credit</p>
                                        </div>
                                        <div>
                                            <p style={{ fontFamily: "DINCondensedC", textAlign: "center", color: changeAvailableColor(values?.total_amount_saved || ''), fontSize: '24px' }}>{formatNumber(values?.total_amount_saved) || 0}</p>
                                            <p>Available</p>
                                        </div>
                                        {/* <div style={{color:"white", width:"50px", fontSize: "12px", padding:"0 .5rem", borderRadius:"5px", background : isDateDue(app?.due_date) ? "red" : "green"}} >{convertDate(app?.due_date)}</div> */}

                                    </FlexComponent>
                                    <FlexComponent style={{ width: "100%", padding: '1rem 0' }} flexDirection="column" alignItems="start">
                                        <a href="#getStarted" style={{ color: ((touched.firstName && errors.firstName) || (touched.lastName && errors.lastName) || (touched.language && errors.language)) ? 'red' : '' }} onClick={(e) => handleClick(e, 'getStarted')}>Get Started</a>
                                        <a href="#workAndIncome" style={{ color: ((touched.work_history && errors.work_history)) ? 'red' : '' }} onClick={(e) => handleClick(e, 'workAndIncome')}>Work & Income</a>
                                        {(changeIncomeColor(values) === "#EB4243" || changeIncomeColor(values) === "orange") && <p style={{ borderRadius: "5px", color: "blue", fontSize: "10px", border: "1px solid grey", padding: "3px" }} >Make sure you ask questions about any additional sources of income, and about a cosigner to help strengthen this application</p>}
                                        <a href="#savingsAssets" style={{ color: ((touched.total_amount_saved && errors.total_amount_saved)) ? 'red' : '' }} onClick={(e) => handleClick(e, 'savingsAssets')}>Savings & Assets</a>
                                        {(changeAvailableColor(values?.total_amount_saved) === "red" || changeAvailableColor(values?.total_amount_saved) === "#FF9A00") && <p style={{ borderRadius: "5px", color: "blue", fontSize: "10px", border: "1px solid grey", padding: "3px" }} >Please make sure to ask all of the questions about savings including do they have any family friends or relatives who has ever suggested they would be willing to help with a gift of cash towards your down payment or closing costs,  and dont forget to ask about any retirement accounts, savings accounts, or investments they may have.
                                        </p>}
                                        <a href="#YourCredit" style={{ color: ((touched.estimated_credit_score && errors.estimated_credit_score)) ? 'red' : '' }} onClick={(e) => handleClick(e, 'YourCredit')}>Credit</a>
                                        <a href="#addressId" style={{ color: ((touched.address_history && errors.address_history)) ? 'red' : '' }} onClick={(e) => handleClick(e, 'addressId')}>Address</a>
                                        <a href="#contactId" style={{ color: ((touched.cell_phone_number && errors.cell_phone_number) || (touched.email && errors.email)) ? 'red' : '' }} onClick={(e) => handleClick(e, 'contactId')}>Contact</a>
                                        <a href="#homeTypeId" style={{ color: ((touched.do_you_have_a_cosigner && errors.do_you_have_a_cosigner)) ? 'red' : '' }} onClick={(e) => handleClick(e, 'homeTypeId')}>Home Type</a>
                                        {values.do_you_have_a_cosigner === "Yes" && <a href="#cosignerId" onClick={(e) => handleClick(e, 'cosignerId')}>Cosigner</a>}
                                        <a href="#internalDetailsId" style={{ color: ((touched.lead_source && errors.lead_source) || (touched.application_pipeline && errors.application_pipeline) || (touched.application_by && errors.application_by)) || ((touched.which_homes_for_sale_region && errors.which_homes_for_sale_region)) ? 'red' : '' }} onClick={(e) => handleClick(e, 'internalDetailsId')}>Internal Details</a>
                                    </FlexComponent>
                                    {availableUsers.length > 0 && <div>
                                    <h3>Available Users</h3>
                                    {availableUsers?.map((user) => (
                                        <FlexComponent style={{ marginTop: ".5rem" }} justifyContent="start" >
                                            <Avatar image={user.image} size="small" label={<span style={{ padding: '5px' }}>{getInitials(user?.name)}</span>} shape="circle" style={{ marginRight: '8px' }} />
                                            {user.name}
                                        </FlexComponent>
                                    ))}
                                    </div>}
                                </FlexComponent>
                            </FlexComponent>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default Form